import { createButtonIconOnly } from '@vfde-brix/ws10/button-icon-only';
import { fetchOverlay } from '@vfde-sails/overlay';
import {
    getContentUrlFromWindow,
    getHiddenNotchFromWindow,
} from '../container/Flyout/helper/windowObjectHelpers';
import { useDispatch } from '../app/store';

/**
 * Mount button icon only
 */
export const mountButtonIconOnly = (wrapperId: string): void => {
    const dispatch = useDispatch();

    const wrapperElement = document.getElementById(wrapperId);

    const buttonIcon = wrapperElement && createButtonIconOnly(wrapperElement, {
        onClick: () => {
            const url = getContentUrlFromWindow();

            url && dispatch(fetchOverlay({ url }));
        },
    });

    buttonIcon?.toggleContainer(getHiddenNotchFromWindow());
};
