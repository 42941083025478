import {
    URL_TOBI_CSS,
    URL_TOBI_JS,
} from '../constant';

/**
 * Load JS Resources for TOBi webchat
 */
export const loadTobiScript = () => new Promise((resolve, reject): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = URL_TOBI_JS;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
});

/**
 * Load CSS Resources for TOBi webchat
 */
export const loadTobiCSS = () => new Promise((resolve, reject): void => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = URL_TOBI_CSS;
    link.onload = resolve;
    link.onerror = reject;
    document.head.appendChild(link);
});
