import { trackIt } from '@vfde-sails/tracking';
import {
    FLYOUT_TRACKING_ACTION,
    FLYOUT_TRACKING_ACTION_ENGAGE,
    FLYOUT_TRACKING_ACTION_REVEAL,
    FLYOUT_TRACKING_AVAILABLE_TOOLS,
    FLYOUT_TRACKING_EVENT,
    FLYOUT_TRACKING_PHONE_NUMBER,
    FLYOUT_TRACKING_SUBJECT,
    FLYOUT_TRACKING_TOOL,
    FLYOUT_TRACKING_TRIGGER,
    FLYOUT_TRACKING_TRIGGER_BUTTON,
    FLYOUT_TRACKING_TRIGGER_ICON,
    FLYOUT_TRACKING_TRIGGER_LINK,
    FLYOUT_TRACKING_VARIANT,
    FLYOUT_TRACKING_VARIANT_REACTIVE,
} from '../constant';

/**
 * track flyout opening
 */
export const trackFlyoutOpening = (availableTools: string[]) => {
    trackIt(
        FLYOUT_TRACKING_EVENT,
        {
            [FLYOUT_TRACKING_ACTION]: FLYOUT_TRACKING_ACTION_REVEAL,
            [FLYOUT_TRACKING_SUBJECT]: 'contact',
            [FLYOUT_TRACKING_TRIGGER]: FLYOUT_TRACKING_TRIGGER_ICON,
            [FLYOUT_TRACKING_AVAILABLE_TOOLS]: availableTools,
            [FLYOUT_TRACKING_VARIANT]: FLYOUT_TRACKING_VARIANT_REACTIVE,
        },
    );
};

/**
 * Track view change
 */
export const trackFlyoutViewChange = (subject: string, availableTools: string[]) => {
    trackIt(
        FLYOUT_TRACKING_EVENT,
        {
            [FLYOUT_TRACKING_ACTION]: FLYOUT_TRACKING_ACTION_REVEAL,
            [FLYOUT_TRACKING_SUBJECT]: subject,
            [FLYOUT_TRACKING_TRIGGER]: FLYOUT_TRACKING_TRIGGER_BUTTON,
            [FLYOUT_TRACKING_AVAILABLE_TOOLS]: availableTools,
            [FLYOUT_TRACKING_VARIANT]: FLYOUT_TRACKING_VARIANT_REACTIVE,
        },
    );
};

/**
 * track flyout engagement
 */
export const trackFlyoutButtonEngage = (tool: string, hotline?: string) => {
    trackIt(
        FLYOUT_TRACKING_EVENT,
        {
            [FLYOUT_TRACKING_ACTION]: FLYOUT_TRACKING_ACTION_ENGAGE,
            [FLYOUT_TRACKING_SUBJECT]: tool,
            [FLYOUT_TRACKING_TOOL]: tool,
            [FLYOUT_TRACKING_TRIGGER]: FLYOUT_TRACKING_TRIGGER_LINK,
            [FLYOUT_TRACKING_AVAILABLE_TOOLS]: [tool],
            [FLYOUT_TRACKING_VARIANT]: FLYOUT_TRACKING_VARIANT_REACTIVE,
            ...hotline && { [FLYOUT_TRACKING_PHONE_NUMBER]: hotline },
        },
    );
};
