import {
    createFlyout,
    Flyout,
} from '@vfde-brix/ws10/flyout';
import { startAppListening } from '../app/listener';
import {
    startTobi,
    stopTobi,
} from '../container/Flyout/slice';
import { useDispatch } from '../app/store';
import {
    closeOverlay,
    openOverlay,
    selectOverlayContextForUrl,
} from '@vfde-sails/overlay';
import {
    BEACON_FLYOUT_EVENT,
    BEACON_EVENT_TOGGLE_FLYOUT_SUCCESS,
    TOBI_WEBCHAT_PANEL_ID,
    HOTLINE_PANEL_ID,
} from '../container/Flyout/constant';
import beacon from '@vfde-sails/beacon';
import {
    trackFlyoutOpening,
    trackFlyoutViewChange,
} from '../container/Flyout/helper/tracking';

const mountFlyout = (container: HTMLElement): Flyout => {
    const dispatch = useDispatch();

    const flyout = createFlyout(container, {
        onViewShow: id => {
            // Whenever a view becomes visible, let matelso scan for phone numbers to replace
            window.mtls?.scan();

            // Publish active view panel
            beacon.publish(BEACON_FLYOUT_EVENT,
                {
                    type: BEACON_EVENT_TOGGLE_FLYOUT_SUCCESS,
                    payload: {
                        activePage: id,
                    },
                });

            const tracking = flyout.getProperties().views.find(panel => panel.stdId === id)?.stdTrackingName;

            tracking && trackFlyoutViewChange(tracking, flyout.getAvailableToolsForPanel(id));
        },
        onViewHide: id => {
            // Publish inactive view panel
            beacon.publish(BEACON_FLYOUT_EVENT, {
                type: BEACON_EVENT_TOGGLE_FLYOUT_SUCCESS,
                payload: {
                    activePage: null,
                },
            });

            if (id === TOBI_WEBCHAT_PANEL_ID) {
                // This triggers a listener in the overlay, but it should also stop the webchat itself.
                // Unfortunately the webchat bundle (SAILS app) doesn't expose the disconnect logic to the window.
                // We should maybe request that, because otherwise the tobi API keeps getting called every single second,
                // even when the flyout is closed.
                dispatch(stopTobi());
            }
        },
    });

    startAppListening({
        actionCreator: openOverlay,
        effect: (_, { getState }) => {
            const overlayContext = selectOverlayContextForUrl(getState());

            // If the overlay was opened via hotline link in global navigation, directly show the hotline panel.
            if (overlayContext?.hotline) {
                flyout.open(HOTLINE_PANEL_ID);
            }

            trackFlyoutOpening(flyout.getAvailableTools());
        },
    });

    startAppListening({
        actionCreator: startTobi.fulfilled,
        effect: () => {
            flyout.open(TOBI_WEBCHAT_PANEL_ID);
        },
    });

    startAppListening({
        actionCreator: closeOverlay,
        effect: () => {
            flyout.close();
        },
    });

    return flyout;
};

export default mountFlyout;
