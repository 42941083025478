import { configureStore } from '@reduxjs/toolkit';
import { listener } from './listener';
import appSlice from '../container/Flyout/slice';
import {
    overlayApi,
    overlaySlice,
} from '@vfde-sails/overlay';

export const store = configureStore({
    reducer: {
        [appSlice.reducerPath]: appSlice.reducer,
        [overlayApi.reducerPath]: overlayApi.reducer,
        [overlaySlice.reducerPath]: overlaySlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .prepend(listener.middleware) // Listener goes first because of special actions in it
        .concat(overlayApi.middleware),
});

/**
 * Infer the `RootState` and `AppDispatch` types from the store itself
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Infer AppDispatch
 */
export type AppDispatch = typeof store.dispatch;

/**
 * Export dispatch function
 */
export const useDispatch = () => store.dispatch;
