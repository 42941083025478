import { startAppListening } from '../../app/listener';
import { waitForContactInfo } from './slice';
import beacon from '@vfde-sails/beacon';
import {
    BEACON_FLYOUT_EVENT,
    BEACON_EVENT_SET_CONTACT_INFO,
} from './constant';

startAppListening({
    actionCreator: waitForContactInfo.fulfilled,
    effect: action => {
        // Publish contact information to global navigation contact section
        beacon.publish(BEACON_FLYOUT_EVENT,
            {
                type: BEACON_EVENT_SET_CONTACT_INFO,
                payload: {
                    simplicityContactInfo: {
                        ...action.payload,
                    },
                },
            });
    },
});
