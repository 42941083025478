// tslint:disable:completed-docs

/**
 * IDs
 */
export const BUTTON_TRIGGER_FLYOUT = 'contact-flyout__trigger';
export const CONTAINER_FLYOUT_OVERLAY = 'flyout-overlay';
export const TOBI_WEBCHAT_BUTTON_CONTAINER_ID = 'tobi-webchat-button';
export const TOBI_WEBCHAT_PANEL_ID = 'flyout-webchat';
export const HOTLINE_PANEL_ID = 'flyout-hotline';
export const TOBI_WEBCHAT_CONTAINER_ID = 'flyout-webchat-container';
export const HILFE_BUTTON_ID = 'hilfe-button';
export const HOTLINE_HEADLINE_ID = 'hotline-header';
export const HOTLINE_OPENING_HOURS_ID = 'default-opening-hours';

/**
 * Class names
 */
export const FLYOUT_CLASSNAME = 'contact-flyout';
export const CONTAINER_HOTLINE_BUTTON = 'call-hotline-button-container';

/**
 * Tracking
 * @todo a lot of this is in the new v4 canary of the tracking library
 */
export const FLYOUT_TRACKING_EVENT = 'contact';
export const FLYOUT_TRACKING_ACTION = 'contact_action';
export const FLYOUT_TRACKING_ACTION_REVEAL = 'reveal';
export const FLYOUT_TRACKING_ACTION_ENGAGE = 'engage';
export const FLYOUT_TRACKING_SUBJECT = 'contact_subject';
export const FLYOUT_TRACKING_TOOL = 'contact_tool';
export const FLYOUT_TRACKING_TOOL_HOTLINE = 'hotline';
export const FLYOUT_TRACKING_TOOL_CHAT = 'chat';
export const FLYOUT_TRACKING_TOOL_ONLINE = 'online';
export const FLYOUT_TRACKING_TRIGGER = 'contact_trigger';
export const FLYOUT_TRACKING_TRIGGER_ICON = 'icon';
export const FLYOUT_TRACKING_TRIGGER_BUTTON = 'button';
export const FLYOUT_TRACKING_TRIGGER_LINK = 'link';
export const FLYOUT_TRACKING_PHONE_NUMBER = 'contact_phone_number';
export const FLYOUT_TRACKING_AVAILABLE_TOOLS = 'contact_available_tools';
export const FLYOUT_TRACKING_VARIANT = 'contact_variant';
export const FLYOUT_TRACKING_VARIANT_REACTIVE = 'reactive';

/**
 * TOBi resources
 */
export const URL_TOBI_JS = '/simplicity/webchat/webchat.bundle.js';
export const URL_TOBI_CSS = '/simplicity/webchat/webchat.bundle.css';
export const TOBI_BOT_NAME = 'TOBi';
export const TOBI_BOT_PATH = '/gms/chat/tobiservice/';
export const TOBI_BOT_TOPIC = 'TOBiService';
export const TOBI_BOT_SUBLINE = 'Dein digitaler Assistent';
export const TOBI_BOT_ICON = '/media/img/icons/mid-render/tobi-chat-header.svg';

/**
 *  Global events
 */
export const WINDOW_EVENT_SET_CONTACT = 'flyout:contactInfoSet';
export const BEACON_FLYOUT_EVENT = 'sails:flyout';
export const BEACON_EVENT_TOGGLE_FLYOUT = 'TOGGLE_FLYOUT';
export const BEACON_EVENT_TOGGLE_FLYOUT_SUCCESS = 'TOGGLE_FLYOUT_SUCCESS';
export const BEACON_EVENT_SET_CONTACT_INFO = 'SET_CONTACT_INFO';
