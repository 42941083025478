import createAppSlice from '../../app/createAppSlice';
import {
    FlyoutContent,
    IFlyoutState,
} from './interface';
import { getContactInfoFromWindow } from './helper/windowObjectHelpers';
import {
    WINDOW_EVENT_SET_CONTACT,
    TOBI_BOT_ICON,
    TOBI_BOT_NAME,
    TOBI_BOT_PATH,
    TOBI_BOT_SUBLINE,
    TOBI_BOT_TOPIC,
    TOBI_WEBCHAT_CONTAINER_ID,
} from './constant';
import {
    loadTobiCSS,
    loadTobiScript,
} from './helper/loadTOBiResources';
import { RootState } from '../../app/store';

const initialState: IFlyoutState = {
    isTobiInitialized: false,
    contactInfo: null,
};

const appSlice = createAppSlice({
    name: 'Flyout',
    initialState,
    reducers: create => {
        const createAThunk = create.asyncThunk.withTypes<{
            rejectValue: string;
        }>();

        return {
            waitForContactInfo: createAThunk<FlyoutContent, void>(async (_, { rejectWithValue }) => {
                const contactInfo = getContactInfoFromWindow();

                if (contactInfo) {
                    return contactInfo;
                }

                try {
                    const result = await new Promise((resolve, reject) => {
                        const timer = setTimeout(() => {
                            reject('No flyout information');
                        }, 2000);

                        window.addEventListener(WINDOW_EVENT_SET_CONTACT, function handleContactInfo (e: CustomEvent) {
                            clearTimeout(timer);
                            resolve(e.detail);

                            window.removeEventListener(WINDOW_EVENT_SET_CONTACT, handleContactInfo);
                        });
                    });

                    return result as FlyoutContent;
                }
                catch (err) {
                    throw rejectWithValue((err as Error).message);
                }
            },
            {
                fulfilled: (state, action) => {
                    state.contactInfo = action.payload;
                },
            }),
            startTobi: createAThunk(async (_, { rejectWithValue, getState }) => {
                try {
                    if (!appSlice.selectors.selectIsTobiInitialized(getState() as RootState)) {
                        await Promise.all([
                            loadTobiCSS(),
                            loadTobiScript(),
                        ]);
                    }

                    const tobiChatContainer = document.getElementById(TOBI_WEBCHAT_CONTAINER_ID);

                    tobiChatContainer && window.webchatApi({
                        path: TOBI_BOT_PATH,
                        topic: TOBI_BOT_TOPIC,
                        botName: TOBI_BOT_NAME,
                        headline: TOBI_BOT_NAME,
                        subline: TOBI_BOT_SUBLINE,
                        imgIconSrc: TOBI_BOT_ICON,
                    }, tobiChatContainer);
                }
                catch (err) {
                    throw rejectWithValue((err as Error).message);
                }

                return;
            },
            {
                fulfilled: state => {
                    state.isTobiInitialized = true;
                },
            }),
            stopTobi: createAThunk<void, void>(() => {
                // This should stop the webchat itself.
                //  Unfortunately the webchat bundle (SAILS app) doesn't expose the disconnect logic to the window.
                //  We should maybe request that, because otherwise the tobi API keeps getting called every single second,
                //  even when the flyout is closed.
            }),
        };
    },
    selectors: {
        selectContactInfo: sliceState => sliceState.contactInfo,
        selectIsTobiInitialized: sliceState => sliceState.isTobiInitialized,
    },
});

export default appSlice;

export const {
    waitForContactInfo,
    startTobi,
    stopTobi,
} = appSlice.actions;

export const {
    selectContactInfo,
    selectIsTobiInitialized,
} = appSlice.selectors;
