import { BeaconSetActivePage } from './interface';
import { getContentUrlFromWindow } from './helper/windowObjectHelpers';
import {
    BUTTON_TRIGGER_FLYOUT,
    BEACON_FLYOUT_EVENT,
    BEACON_EVENT_TOGGLE_FLYOUT,
} from './constant';
import beacon, { BeaconAction } from '@vfde-sails/beacon';
import { useDispatch } from '../../app/store';
import { mountOverlay } from '../../components/Overlay';
import { fetchOverlay } from '@vfde-sails/overlay';
import { mountButtonIconOnly } from '../../components/ButtonIconOnly';
import { waitForContactInfo } from './slice';
import './listener';

const mountFlyoutFeature = () => {
    const dispatch = useDispatch();

    dispatch(waitForContactInfo());

    mountOverlay();
    mountButtonIconOnly(BUTTON_TRIGGER_FLYOUT);

    // Listen to global navigation contact section for opening
    beacon.subscribe(BEACON_FLYOUT_EVENT, (msg: BeaconAction<BeaconSetActivePage>) => {
        switch (msg.type) {
            case BEACON_EVENT_TOGGLE_FLYOUT: {
                const url = getContentUrlFromWindow();

                url && dispatch(fetchOverlay({ url, context: { hotline: true } }));
                break;
            }
        }
    });
};

export default mountFlyoutFeature;
